import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, GoldieLogo, StrykerLogo } from 'ui';

import './noaccess.css';

const GOLDIE_ACCESS_LINK = 'https://forms.office.com/r/a34VyJhwbe';

const NoAccess = () => {
  const navigate = useNavigate();

  const proceed = () => {
    navigate('/login');
  };
  return (
    <main data-testid="noaccess-container" className="noaccess-page">
      <article>
        <StrykerLogo />
        <GoldieLogo />
        <p>This tool is only available to the DRE AI team.</p>
        <p>
          To request access please submit{' '}
          <a data-testid="goldie-training-link" href={GOLDIE_ACCESS_LINK}>
            this form
          </a>
          .
        </p>

        <p>
          <Button
            data-testid="noaccess-proceed"
            className="proceed"
            onClick={() => proceed()}
          >
            Go back to sign-in page
          </Button>
        </p>
      </article>
    </main>
  );
};

export default NoAccess;
