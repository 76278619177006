import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'ui/atoms';
import ContextButton from 'ui/molecules/ContextButton';
import { loadUserLimits, UserStore } from 'stores/user';
import useUserModelLimits from 'services/hooks/useUserModelLimits';

import { ReactComponent as IconCaretDown } from './caret-down.svg';
import './gpt-model-selection.css';

export const buildGptModelDescription = (description, isAtLimit) => {
  if (!isAtLimit) {
    return description;
  }
  return (
    <>
      <span className="warning" data-testid="zero-prompts-remaining">
        0 prompts remaining this month
      </span>
      {description}
    </>
  );
};

const GptModelSelection = ({
  setGptModel,
  gptModel,
  gptModels,
  gptModelAutomaticallySelected,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [gptModelName, setGptModelName] = useState('');
  const exhaustedGptModels = UserStore.useState(
    (s) => s.exhaustedGptModels || [],
  );
  const allPromptsExhausted = UserStore.useState((s) => s.allPromptsExhausted);
  const userLimits = useUserModelLimits();

  useEffect(() => {
    if (showModal) {
      loadUserLimits();
    }
  }, [showModal]);

  useEffect(() => {
    if (gptModels?.length) {
      loadUserLimits();
    }
  }, [gptModels]);

  useEffect(() => {
    if (!gptModels?.length) {
      return;
    }

    const currentModel = gptModels.find((item) => item.name === gptModel);

    const name = currentModel?.name;
    const displayName = currentModel?.displayName || name;
    if (!name) {
      setGptModel(gptModels[0]?.name);
      return;
    }

    setGptModelName(displayName);
  }, [exhaustedGptModels, gptModel, gptModels, setGptModel]);

  return (
    <div className="model-selection">
      <Button
        onClick={() => {
          setShowModal(true);
        }}
        type={'default'}
        data-testid="model-selection"
      >
        <div className="model-selection-text">
          <div className="model-selection-text-container">
            <div
              className={`model-selection-text-content ${
                allPromptsExhausted ? 'all-prompts-exhausted' : ''
              }`}
            >
              {gptModelName}{' '}
              {gptModelAutomaticallySelected && (
                <span className="badge">Auto-selected</span>
              )}
            </div>
            {userLimits && (
              <p className="user-limit-notice">
                {userLimits.promptsLeft} prompt
                {userLimits.promptsLeft !== 1 ? 's' : ''} remaining
              </p>
            )}
          </div>

          <div>
            <IconCaretDown />
          </div>
        </div>
      </Button>
      <Modal
        title="Select AI model"
        open={showModal}
        onOk={() => {
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[]}
        className="select-ai-model"
      >
        <div className="gpt-model-selection">
          <div className="gpt-model-list">
            {gptModels &&
              gptModels.length > 0 &&
              gptModels.map((model) => {
                return (
                  <div
                    className="gpt-model-item"
                    key={`gpt-selection-${model.id}`}
                  >
                    {model.modelType}
                    <ContextButton
                      isSelected={model.id === gptModel}
                      title={model.displayName}
                      text={buildGptModelDescription(
                        model.description,
                        exhaustedGptModels.includes(model.id),
                      )}
                      value={model.id}
                      disabled={
                        model.disabled ||
                        exhaustedGptModels.includes(model.id) ||
                        allPromptsExhausted
                      }
                      setSelected={(value) => {
                        setGptModel(value);
                        setShowModal(false);
                      }}
                      showTitleTag={
                        model.id === gptModel && gptModelAutomaticallySelected
                          ? 'Auto-selected'
                          : ''
                      }
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GptModelSelection;
