import { notification } from 'antd';
import type { NotificationPlacement } from 'antd/es/notification/interface';

type OpenNotificationPropsType = {
  placement?: NotificationPlacement;
  message: string;
  duration?: number;
  type?: 'normal' | 'warning';
};

const useGlobalNotification = () => {
  const [notificationApi, notificationContextHolder] =
    notification.useNotification({
      getContainer: () =>
        document.getElementById('main-content') as HTMLElement,
      maxCount: 10,
      stack: { threshold: 1 },
    });

  const openNotification = ({
    placement = 'top',
    message = '',
    duration = 5,
    type = 'normal',
  }: OpenNotificationPropsType) => {
    if (!message) {
      return;
    }

    notificationApi.open({
      role: 'status',
      message: null,
      description: message,
      duration: duration,
      placement,
      className: type,
    });
  };

  return { openNotification, notificationContextHolder };
};

export const directApiGlobalNotification = ({ message = '' }) => {
  notification.config({
    getContainer: () => document.getElementById('main-content') as HTMLElement,
    maxCount: 10,
  });

  notification.open({
    role: 'status',
    message: null,
    description: message,
    duration: 5,
    placement: 'top',
    className: 'normal',
  });
};

export default useGlobalNotification;
