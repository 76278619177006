import { ReactComponent as WebIcon } from './web.svg';
import { ReactComponent as DocumentIcon } from './file.svg';

const fileTypes = ['.pdf', '.ppt', '.pptx', '.doc', '.docx', '.txt'];
function isFileType(displayName) {
  return fileTypes.some((fileType) => displayName.endsWith(fileType));
}

function getOriginTypeIcon(displayName) {
  if (!isFileType(displayName)) {
    return <WebIcon data-testid="source-icon" />;
  }

  return <DocumentIcon data-testid="source-icon" />;
}

function getSourceHrefValue(source) {
  return source.url || false;
}

const SourceItem = ({ source }) => {
  const url = getSourceHrefValue(source);
  return (
    <div className="source-item" data-testid="source-item">
      {getOriginTypeIcon(source.displayName)}{' '}
      <div>
        {url ? (
          <a href={getSourceHrefValue(source)} target="_blank" rel="noreferrer">
            {source.displayName}
          </a>
        ) : (
          <span>{source.displayName}</span>
        )}
      </div>
    </div>
  );
};

export default SourceItem;
